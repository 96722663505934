import axios from "axios";
const DOMAIN_NAME = process.env.REACT_APP_DOMAIN_NAME;

const API_URL = DOMAIN_NAME + "/api/auth/";

var browserDomain;
    
if (window.location.href.startsWith('https')) {
  // Starts with https://
  browserDomain = API_URL;
} else {
  const url = new URL(window.location.href);
  
  const setDomain = new URL(DOMAIN_NAME);
  
  if (url.hostname !== setDomain.hostname) {
    // Using hostname.local
    browserDomain = `${url.protocol}//${url.hostname}:${setDomain.port}/api/auth/`;
  } else {
    // Using localhost
    browserDomain = API_URL;
  }
}

class AuthService {
  login(username, password) {
    return axios
      .post(browserDomain + "signin", {
        username,
        password
      }, {
        withCredentials: true
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }

        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
    return axios
      .post(browserDomain + "signOut", {}, {
        withCredentials: true
      })
      .then(response => {
        return response.data;
      });
  }

  register(username, email, password) {
    return axios.post(browserDomain + "signup", {
      username,
      email,
      password
    });
  }

  editRole(username, roles) {
    return axios
      .post(browserDomain + "editPermission", {
        username,
        roles
      }, {
        withCredentials: true
      })
      .then(response => {
        return response.data;
      });
  }

  deleteUser(username) {
    return axios
      .post(browserDomain + "deleteUser", {
        username
      }, {
        withCredentials: true
      })
      .then(response => {
        return response.data;
      });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));;
  }
}

const authServiceInstance = new AuthService();
export default authServiceInstance;