import axios from 'axios';
import authHeader from './auth-header';
const DOMAIN_NAME = process.env.REACT_APP_DOMAIN_NAME;

const API_URL = DOMAIN_NAME + "/api/test/";

var browserDomain;

if (window.location.href.startsWith('https')) {
  // Starts with https://
  browserDomain = API_URL;
} else {
  const url = new URL(window.location.href);
  
  const setDomain = new URL(DOMAIN_NAME);
  
  if (url.hostname !== setDomain.hostname) {
    // Using hostname.local
    browserDomain = `${url.protocol}//${url.hostname}:${setDomain.port}/api/test/`;
  } else {
    // Using localhost
    browserDomain = API_URL;
  }
}

class UserService {
  getPublicContent() {
    return axios.get(browserDomain + 'all');
  }

  getUserBoard() {
    return axios.get(browserDomain + 'user', { headers: authHeader() });
  }

  getAdminOrMasterBoard() {
    return axios.get(browserDomain + 'admin-master', { headers: authHeader() });
  }

  getUnauthorizedBoard() {
    return axios.get(browserDomain + 'unauthorized-user', { headers: authHeader() });
  }
}

const userServiceInstance = new UserService();
export default userServiceInstance;