import React, { Component } from "react";
import { IconContext } from "react-icons";
import { RiDeleteBin5Fill } from "react-icons/ri";

import UserService from "../services/user.service";
import AuthService from "../services/auth.service";
import EventBus from "../common/EventBus";

/**
 * Settings component for managing team members and roles.
 */
export default class Settings extends Component {
  /**
   * Constructor for the Settings component.
   * 
   * @param {Object} props - The properties passed to the component.
   */
  constructor(props) {
    super(props);

    /**
     * The state of the component.
     * 
     * @type {Object}
     * @property {Array} content - The list of users.
     * @property {Object} selectedRoles - The selected roles for each user.
     * @property {String} successMessage - The success message to display.
     * @property {String} errorMessage - The error message to display.
     */
    this.state = {
      content: [],
      selectedRoles: {},
      successMessage: "",
      errorMessage: ""
    };
  }

  /**
   * Lifecycle method called when the component mounts.
   */
  componentDidMount() {
    /**
     * Get the list of admin or admin users.
     */
    UserService.getAdminOrMasterBoard().then(
      (response) => {
        this.setState({
          content: response.data.users
        });
      },
      (error) => {
        this.setState({
          content:
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString()
        });

        if (error.response && error.response.status === 401) {
          EventBus.dispatch("logout");
        }
      }
    );
  }

  /**
   * Handle the change of a user's role.
   * 
   * @param {Event} event - The event triggered by the role change.
   * @param {String} username - The username of the user.
   */
  handleRoleChange = (event, username) => {
    const newSelectedRoles = { ...this.state.selectedRoles, [username]: event.target.value };
    this.setState({ selectedRoles: newSelectedRoles });
  };

  /**
   * Save the changes to the user roles.
   */
  saveRoleChange = () => {
    const { selectedRoles, content } = this.state;
    
    // Count existing admin
    const currentAdmins = content.filter(user => user.role === 'admin').length;
    
    // Count potential admins after changes
    const changesToAdmin = Object.values(selectedRoles).filter(role => role === 'admin').length;
    const changesFromAdmin = Object.entries(selectedRoles).filter(([username, role]) => {
      const user = content.find(u => u.username === username);
      return user && user.role === 'admin' && role !== 'admin';
    }).length;

    const totalAdmins = currentAdmins + changesToAdmin - changesFromAdmin;

    // If there are no Admins, allow assigning the first one
    if (currentAdmins > 0 && totalAdmins < 1) {
      this.setState({
        errorMessage: "There must be at least one admin.",
        successMessage: ""
      });
      return;
    }

    const promises = Object.entries(selectedRoles).map(([username, role]) =>
      AuthService.editRole(username, role)
    );

    Promise.all(promises)
      .then(() => {
        this.setState(
          {
            successMessage: "Roles updated successfully!",
            errorMessage: "",
            selectedRoles: {}
          },
          () => {
            window.location.reload();
          }
        );
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: "An error occurred while updating roles. Please try again.",
          successMessage: ""
        });
      });
  };

  /**
   * Delete a user.
   * 
   * @param {String} username - The username of the user to delete.
   */
  deleteUser = (username) => {
    // Check if the user is the last admin
    const { content } = this.state;
    const admins = content.filter(user => user.role === 'admin');

    if (admins.length === 1 && admins[0].username === username) {
      this.setState({
        errorMessage: "Cannot delete the last admin.",
        successMessage: ""
      });
      return;
    }

    const confirmDelete = window.confirm(`Are you sure you want to delete user ${username}?`);
    if (!confirmDelete) {
      return; // Exit if the user cancels the confirmation
    }

    AuthService.deleteUser(username)
      .then(() => {
        this.setState({
          successMessage: `User ${username} deleted successfully!`,
          errorMessage: ""
        });

        // Update the state to remove the deleted user from the list
        this.setState((prevState) => ({
          content: prevState.content.filter(user => user.username !== username)
        }));
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          errorMessage: `Failed to delete user ${username}. Please try again.`,
          successMessage: ""
        });
      });
  };

  /**
   * Render the component.
   * 
   * @return {JSX} The JSX to render.
   */
  render() {
    const { content, successMessage, errorMessage, selectedRoles, currentAdmins } = this.state;
    const hasRoleChanges = Object.keys(selectedRoles).length > 0;

    return (
      <div>
        <header className="header">
          <h1 className="page-title">Account & Members</h1>
          <div className="page-description">Manage team members and roles</div>
        </header>

        <div className="permission-section">
          <div className="content-box">
            {Array.isArray(content) ? (
              content.map((user, index) => {
                const isLastAdmin = content.filter(u => u.role === 'admin').length === 1 && user.role === 'admin';

                return (
                  <div className="profile" key={index}>
                    <div className="profile-info">
                      <div>
                        <div className="username">@{user.username}</div>
                        {user.role ? (
                          <div className="role">{user.role}</div>
                        ) : (
                          <div className="new-user">New User</div>
                        )}
                      </div>
                    </div>

                    {user.role === "master" ? (
                      <p className="admin-role d-role">Master</p>
                    ) : (
                      <div className="selection">
                        <div className="role-select">
                          <select
                            name="roles"
                            className="d-role"
                            id="role-changed"
                            onChange={(e) => this.handleRoleChange(e, user.username)}
                            defaultValue={user.role}
                            disabled={isLastAdmin && currentAdmins > 1} // Disable if last admin and more than 1 admin exists
                          >
                            <option value="">---</option>
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                          </select>
                        </div>
                        <button
                          className="delete-user"
                          onClick={() => this.deleteUser(user.username)}
                          disabled={isLastAdmin && currentAdmins > 1} // Disable if last admin and more than 1 admin exists
                        >
                          <IconContext.Provider value={{ className: "delete-user-icon" }}>
                            <RiDeleteBin5Fill />
                          </IconContext.Provider>
                        </button>
                      </div>
                    )}
                  </div>
                );
              })
            ) : (
              <div>{content}</div>
            )}

            {errorMessage && (
              <div className="error-prompt">
                <div className="error-img">
                  <svg aria-hidden="true" className="error-icon" focusable="false" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
                  </svg>
                </div>
                <div>
                  <span className="error-message">{errorMessage}</span>
                </div>
              </div>
            )}

            {successMessage && <span className="success-message">{successMessage}</span>}
            
            {hasRoleChanges && (
              <div className="form-group">
                <button
                  className="btn btn-primary btn-block"
                  onClick={this.saveRoleChange}
                >
                  Save Changes
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}