import React, { Component } from "react";

import UserService from "../services/user.service";

import printerImage from "../img/3D-Printer-isometric-Icon.jpg";
import furnaceImage from "../img/Vacuum_Furnace-isometric-Icon.jpg";

const PRINTER_DOMAIN_NAME = process.env.REACT_APP_PRINTER_DOMAIN_NAME;
const FURNACE_DOMAIN_NAME = process.env.REACT_APP_FURNACE_DOMAIN_NAME;

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    UserService.getPublicContent().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {
    return (
      <div className="home-page">
        <div className="home-title"><h1>Select your service:</h1></div>
        <div className="app-container">
          <div className="app-item">
            <a href={ PRINTER_DOMAIN_NAME } className="reroute-link">
              <div className="display">
                <img src={printerImage} alt="Printer" className="display-img"/>
              </div>
              <div className="text-ctn">
                <div className="description">
                  <span>SL Printer</span>
                </div>
                <div className="hyperlink">
                  { PRINTER_DOMAIN_NAME }
                </div>
              </div>
            </a>
          </div>

            <div className="app-item">
              <a href={ FURNACE_DOMAIN_NAME } className="reroute-link">
                <div className="display">
                  <img src={furnaceImage} alt="Furnace" className="display-img"/>
                </div>
                <div className="text-ctn">
                  <div className="description">
                    <span>SL Furnace</span>
                  </div>
                  <div className="hyperlink">
                    { FURNACE_DOMAIN_NAME }
                  </div>
                </div>
              </a>
            </div>
        </div>
      </div>
    );
  }
}
